import React, { useState, useEffect } from "react"
import { ethersToSerializedBigNumber } from "../../utils/bigNumber"
import { getLotteryV3Contract } from "../../utils/contractHelpers"
import { getBalanceNumber } from "../../utils/formatBalance"
import { useTranslation } from "gatsby-plugin-react-i18next"

const PrizeFund = () => {
  const { t } = useTranslation()
  const [lotteryId, setLotteryId] = useState()
  const [prizeFund, setPrizeFund] = useState(0)
  const lotteryContract = getLotteryV3Contract()

  useEffect(() => {
    const fetchLotteryId = async () => {
      try {
        const lotteryId = await lotteryContract.currentLotteryId()
        setLotteryId(ethersToSerializedBigNumber(lotteryId))
      } catch (err) {
        console.log(err)
        return err
      }
    }
    const fetchLottery = async () => {
      if (lotteryId) {
        try {
          const lotteryData = await lotteryContract.viewLottery(
            ethersToSerializedBigNumber(lotteryId)
          )
          setPrizeFund(
            ethersToSerializedBigNumber(lotteryData.amountCollectedInMoon)
          )
        } catch (err) {
          console.log(err)
          return err
        }
      }
    }
    fetchLotteryId()
    fetchLottery()
  })

  return (
    <div className="container w-full lg:w-1/2 rounded-2xl bg-[#06090e] drop-shadow-[0px_0px_12px_rgba(143,248,255,0.25)]">
      <div className="container flex flex-row px-2 py-3 my-0 lg:my-4 items-center justify-center">
        <h5 className="font-black text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
          {t("TOTAL PRIZE FUNDS")}
        </h5>
      </div>
      <div className="flex flex-row justify-center overflow-hidden">
        <div className="flex flex-row px-2 pb-3 mb-3 my-0 items-center justify-center ">
          {getBalanceNumber(prizeFund).toLocaleString("en-US")} USDT
        </div>
      </div>
    </div>
  )
}

export default PrizeFund
