import React, { useState, useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { getGraphLotteryUser } from "./helper"
import { getBalanceNumber } from "../../utils/formatBalance"

const Mockup = [
  "0x197A... won $11,304 with only $73 deposited!",
  "0x583E... won $5,642 with only $100 deposited!",
  "0x946D... won $9,271 with only $85 deposited!",
]
const Winners = () => {
  const { t } = useTranslation()
  const [winner, setWinner] = useState(0)
  const [userReward, setUserReward] = useState([])
  const [userBuyAmount, setUserBuyAmount] = useState([])
  const [address, setAddress] = useState([])

  useEffect(() => {
    const interval = setInterval(() => {
      if (winner === 3) {
        setWinner(1)
      } else {
        setWinner(winner + 1)
      }
    }, 5000)
    return () => clearInterval(interval)
  }, [winner])

  useEffect(() => {
    const getGraphWinner = async () => {
      const userRewardData = []
      const userBuyData = []
      const userAddress = []
      const user = await getGraphLotteryUser()
      if (user) {
        for (let i = 0; i < user.length; i++) {
          if (user[i].profit > 0) {
            userRewardData.push(user[i].rewardAmount)
            userBuyData.push(user[i].totalBuyAmount)
            userAddress.push(user[i].user.id)
          }
        }
        setUserReward(userRewardData)
        setUserBuyAmount(userBuyData)
        setAddress(userAddress)
      }
    }
    getGraphWinner()
  }, [])

  const accountEllipsis = account => {
    return (
      account &&
      account
        .substring(0, 6)
        .concat("...")
        .concat(account.substring(account.length - 4, account.length))
    )
  }

  return (
    <div className="w-full lg:w-1/2 rounded-2xl bg-[#06090e] drop-shadow-[0_0_12px_rgba(143,248,255,0.25)] overflow-hidden">
      <div className="container flex flex-row px-2 py-3 my-0 lg:my-4 items-center justify-center">
        <h5 className="font-black text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 text-center">
          {t("LUCKIEST WINNERS")}
        </h5>
      </div>
      <div className="flex flex-row justify-center overflow-hidden">
        <div
          className={
            winner === 1
              ? "lg:absolute px-2 pb-3 mb-3 my-0 items-center text-center justify-center translate-x-[-185%] duration-700"
              : winner === 0
              ? "lg:absolute px-2 pb-3 mb-3 my-0 items-center text-center justify-center"
              : winner === 3
              ? "lg:absolute px-2 pb-3 mb-3 my-0 items-center text-center justify-center translate-x-0 duration-700"
              : "lg:absolute px-2 pb-3 mb-3 my-0 items-center text-center justify-center translate-x-[42rem]"
          }
        >
          {userReward[0]
            ? `${accountEllipsis(address[0])} won $${getBalanceNumber(
                userReward[0]
              )} with only $${getBalanceNumber(userBuyAmount[0])} deposited!`
            : `${accountEllipsis(
                "0x0130e41f011e55c7a4ab1f8bdf3a1457a0016cc8"
              )} won $100 with only $37.7 deposited!`}
        </div>
        <div
          className={
            winner === 2
              ? "absolute px-2 pb-3 mb-3 my-0 items-center text-center justify-center translate-x-[-185%] duration-700"
              : winner === 1
              ? "absolute px-2 pb-3 mb-3 my-0 items-center text-center justify-center translate-x-0 duration-700"
              : "absolute px-2 pb-3 mb-3 my-0 items-center text-center justify-center translate-x-[42rem]"
          }
        >
          {userReward[1]
            ? `${accountEllipsis(address[1])} won $${getBalanceNumber(
                userReward[1]
              )} with only $${getBalanceNumber(userBuyAmount[1])} deposited!`
            : `${accountEllipsis(
                "0x2506bf590c4e1f28f8c212e89913899ff7f32169"
              )} won $100 with only $37.7 deposited!`}
        </div>
        <div
          className={
            winner === 3
              ? "absolute px-2 pb-3 mb-3 my-0 items-center text-center justify-center translate-x-[-185%] duration-700"
              : winner === 2
              ? "absolute px-2 pb-3 mb-3 my-0 items-center text-center justify-center translate-x-0 duration-700"
              : "absolute px-2 pb-3 mb-3 my-0 items-center text-center justify-center translate-x-[42rem]"
          }
        >
          {userReward[2]
            ? `${accountEllipsis(address[2])} won $${getBalanceNumber(
                userReward[2]
              )} with only $${getBalanceNumber(userBuyAmount[2])} deposited!`
            : `${accountEllipsis(
                "0x2506bf590c4e1f28f8c212e89913899ff7f32169"
              )} won $100 with only $37.7 deposited!`}
        </div>
      </div>
    </div>
  )
}

export default Winners
