import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Rocket from "../../images/rocket.png"
import Logo from "../../images/megamoon-logo.png"
import Planet1 from "../../images/planet-1.webp"
import Planet2 from "../../images/planet-2.webp"
import Planet3 from "../../images/planet-3.webp"
import Winners from "../Winners"
import PrizeFund from "../PrizeFund"
import BgImage from "../../images/bg-image-2.webp"

const Hero = () => {
  const { t } = useTranslation()
  return (
    <section className="relative bg-cover bg-no-repeat bg-bottom overflow-hidden pt-16 pb-8 lg:pt-24 lg:overflow-visible ">
      <img className="hidden" src={Logo} alt="Megamoon Logo" />
      <img
        className="absolute sm:left-48 left-40 top-80"
        src={Rocket}
        alt="Megamoon Rocket"
      />
      <img className="absolute top-48" src={Planet1} alt="" />
      <img
        className="container absolute sm:right-10 sm:top-[-0.7rem] opacity-50"
        src={BgImage}
        alt=""
      />
      <img className="absolute right-0 top-[-2.7rem]" src={Planet2} alt="" />
      <img
        className="absolute right-0 lg:bottom-[-17rem] bottom-[-6.8rem]"
        src={Planet3}
        alt=""
      />
      <div className="container mx-auto lg:flex lg:justify-between">
        <div className="relative z-10 lg:w-3/5">
          <h1 className="lg:leading-tight text-2xl lg:text-5xl font-black text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
            {t("Win Earn Trade")}
          </h1>
          <h2 className="mt-1 lg:mt-6 font-normal text-xl lg:text-4xl">
            {t("by the MegaMoon")}
          </h2>
          <div className="mt-6 flex lg:container lg:mx-auto lg:px-0">
            <div inline text-base>
              {t(
                "Making money can be exciting and fair. MegaMoon has created a decentralized lottery platform. The agreement between the lottery and the player is written in a self-exciting contract."
              )}
            </div>
          </div>
          <Link
            className="block text-xs lg:text-base font-medium mt-4 lg:mt-2"
            hrefLang="en"
            target="_blank"
            rel="noopener noreferrer nofollow"
            href="https://docs.megamoon.space/"
          >
            {t("Learn more")}
          </Link>
          <div className="lg:hidden mt-6 w-full">
            <Link to="https://app.megamoon.space/">
              <button
                class="border rounded-lg text-xs font-bold leading-7 border-sky-300 text-sky-300 px-5"
                type="button"
              >
                {t("Launch App")}
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="relative mt-60 lg:mt-52 xl:mt-72 container flex flex-col lg:flex-row mx-auto gap-2 lg:gap-8 lg:justify-between">
        <Winners />
        <PrizeFund />
      </div>
    </section>
  )
}

export default Hero
