import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/Section/Hero"
import Core from "../components/Section/Core"
import Security from "../components/Section/Security"
import Community from "../components/Section/Community"
import Partner from "../components/Section/Partner"

const IndexPage = () => (
  <Layout>
    <Seo
      title="MegaMoon"
      description="MegaMoon is the leading decentralized Lottery on Polygon Chain, with the highest volumes in the market and MegaMoon exchange : Earn crypto and Trade crypto"
      keywords="MegaMoon, Decentralized Lottery, Polygon Chain, MegaMoon Lottery, MegaMoon exchange, Earn crypto, Trade crypto, Win crypto"
    />
    <Hero />
    <Core />
    <Security />
    <Community />
    <Partner />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo
    title="MegaMoon"
    description="MegaMoon is the leading decentralized Lottery on Polygon Chain, with the highest volumes in the market and MegaMoon exchange : Earn crypto and Trade crypto"
    keywords="MegaMoon, Decentralized Lottery, Polygon Chain, MegaMoon Lottery, MegaMoon exchange, Earn crypto, Trade crypto, Win crypto"
  />
)

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
