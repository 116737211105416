import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation } from "gatsby-plugin-react-i18next"
// import Planet from "../../images/planet.webp"
// import Telegram from "../../images/telegram.svg"
// import Discord from "../../images/discord.svg"
// import Youtube from "../../images/youtube.svg"
// import Twitter from "../../images/twitter.svg"
// import Medium from "../../images/medium.svg"
import StarBg from "../../images/star-bg.svg"
import CircleBlur from "../../images/circle-blurr.svg"
import Planet1 from "../../images/planet-1.webp"
import Planet2 from "../../images/planet-2.webp"
import BgImage from "../../images/bg-image.webp"

const Community = () => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()

  return (
    <section
      className="lg:h-[886px] pt-10 pb-20 lg:pb-10 bg-cover bg-center relative overflow-hidden lg:flex lg:items-center lg:justify-center"
      id="community"
    >
      <img
        className="absolute sm:right-32 sm:top-[-0.7rem] top-48"
        src={BgImage}
        alt=""
      />
      <img className="absolute left-0 bottom-0" src={Planet1} alt="" />
      <img className="absolute right-0 top-0" src={Planet2} alt="" />
      <img
        className="absolute h-full"
        ariaHidden="true"
        src={CircleBlur}
        alt=""
      />
      <img className="absolute h-full" ariaHidden="true" src={StarBg} alt="" />
      <div
        className="container mx-auto mb-10 text-2xl font-medium text-center lg:text-5xl lg:font-semibold z-50 lg:text-yellow-300 lg:w-1/2"
        style={{ fontFamily: "Starcruiser" }}
      >
        {t("Join Our")}
        <br className="hidden lg:block" /> {t("Community")}
      </div>
      <div
        className="container mx-auto max-w-sm lg:max-w-none lg:absolute"
        style={{ left: "50%", top: "50%" }}
      >
        <div
          className="inline-block w-1/2 transform mb-3 last:mb-0 lg:absolute lg:translate-y-0 lg:scale-0 lg:top-0 lg:left-0 origin-left scale-105"
          style={{
            maxWidth: "223px",
            transform: !breakpoints.md ? "translate(-116.5px, -380px)" : "",
          }}
        >
          <Link
            className="relative inline-block w-full"
            target="_blank"
            rel="noopener noreferrer nofollow"
            to="https://t.me/OxMegamoon"
          >
            <StaticImage
              className="w-full"
              src="../../images/planet.webp"
              alt=""
              placeholder="blurred"
            />
            <div className="absolute inset-community lg:inset-community-lg flex flex-col justify-center items-center">
              <StaticImage
                className="w-[4.8rem] lg:w-[6.8rem]"
                src="../../images/telegram.svg"
                alt="Telegram"
                placeholder="blurred"
              />
              <div className="mt-4 font-medium text-base">Telegram</div>
            </div>
          </Link>
        </div>
        <div
          className="inline-block w-1/2 transform mb-3 last:mb-0 lg:absolute lg:translate-y-0 lg:scale-0 lg:top-0 lg:left-0 origin-right scale-105 translate-y-1/2"
          style={{
            maxWidth: "223px",
            transform: !breakpoints.md ? "translate(138px, -230px)" : "",
          }}
        >
          <Link
            className="relative inline-block w-full"
            target="_blank"
            rel="noopener noreferrer nofollow"
            to="https://discord.com/invite/sjecQezdgu"
          >
            <StaticImage
              className="w-full"
              src="../../images/planet.webp"
              alt=""
              placeholder="blurred"
            />
            <div className="absolute inset-community lg:inset-community-lg flex flex-col justify-center items-center">
              <StaticImage
                className="w-[4.8rem] lg:w-[6.8rem]"
                src="../../images/discord.svg"
                alt="Discord"
                placeholder="blurred"
              />
              <div className="mt-4 font-medium text-base">Discord</div>
            </div>
          </Link>
        </div>
        <div
          className="inline-block w-1/2 transform mb-3 last:mb-0 lg:absolute lg:translate-y-0 lg:scale-0 lg:top-0 lg:left-0 origin-left scale-105"
          style={{
            maxWidth: "223px",
            transform: !breakpoints.md ? "translate(87px, 30px)" : "",
          }}
        >
          <Link
            className="relative inline-block w-full"
            target="_blank"
            rel="noopener noreferrer nofollow"
            to="https://www.youtube.com/channel/UCue8nrqCTumj2UaP_b04Mrg"
          >
            <StaticImage
              className="w-full"
              src="../../images/planet.webp"
              alt=""
              placeholder="blurred"
            />
            <div className="absolute inset-community lg:inset-community-lg flex flex-col justify-center items-center">
              <StaticImage
                className="w-[4.8rem] lg:w-[6.8rem]"
                src="../../images/youtube.svg"
                alt="Youtube"
                placeholder="blurred"
              />
              <div className="mt-4 font-medium text-base">Youtube</div>
            </div>
          </Link>
        </div>
        <div
          className="inline-block w-1/2 transform mb-3 last:mb-0 lg:absolute lg:translate-y-0 lg:scale-0 lg:top-0 lg:left-0 origin-right scale-105 translate-y-1/2"
          style={{
            maxWidth: "223px",
            transform: !breakpoints.md ? "translate(-320px, 30px)" : "",
          }}
        >
          <Link
            className="relative inline-block w-full"
            target="_blank"
            rel="noopener noreferrer nofollow"
            to="https://twitter.com/0xMegamoon"
          >
            <StaticImage
              className="w-full"
              src="../../images/planet.webp"
              alt=""
              placeholder="blurred"
            />
            <div className="absolute inset-community lg:inset-community-lg flex flex-col justify-center items-center">
              <StaticImage
                className="w-[4.8rem] lg:w-[6.8rem]"
                src="../../images/twitter.svg"
                alt="Twitter"
                placeholder="blurred"
              />
              <div className="mt-4 font-medium text-base">Twitter</div>
            </div>
          </Link>
        </div>
        <div
          className="inline-block w-1/2 transform mb-3 last:mb-0 lg:absolute lg:translate-y-0 lg:scale-0 lg:top-0 lg:left-0 origin-left scale-105"
          style={{
            maxWidth: "223px",
            transform: !breakpoints.md ? "translate(-370px, -230px)" : "",
          }}
        >
          <Link
            className="relative inline-block w-full"
            target="_blank"
            rel="noopener noreferrer nofollow"
            to="https://medium.com/@0xmegamoon"
          >
            <StaticImage
              className="w-full"
              src="../../images/planet.webp"
              alt=""
              placeholder="blurred"
            />
            <div className="absolute inset-community lg:inset-community-lg flex flex-col justify-center items-center">
              <StaticImage
                className="w-[4.8rem] lg:w-[6.8rem]"
                src="../../images/medium.svg"
                alt="Medium"
                placeholder="blurred"
              />
              <div className="mt-4 font-medium text-base">Medium</div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Community
