import BigNumber from "bignumber.js"
import { ethers } from "ethers"

export const getBalanceAmount = (amount: BigNumber, decimals = 6) => {
  return new BigNumber(amount).dividedBy(new BigNumber(10).pow(decimals))
}

export const getBalanceNumber = (balance: BigNumber, decimals = 6) => {
  return getBalanceAmount(balance, decimals).toNumber()
}
