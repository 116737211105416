import { request, gql } from "graphql-request"

export const MAX_USER_LOTTERIES_REQUEST_SIZE = 100

type UserLotteriesWhere = { lottery_in?: string[] }

export const getGraphLotteryUser = async (
  first = MAX_USER_LOTTERIES_REQUEST_SIZE,
  skip = 0,
  where: UserLotteriesWhere = {}
) => {
  let user
  const blankUser = {
    rounds: [],
  }

  try {
    const response = await request(
      "https://api.thegraph.com/subgraphs/name/megamoon-space/lottery-v3",
      gql`
        query getLotteries($first: Int!, $skip: Int!) {
          rounds(first: 3, orderBy: profit, orderDirection: desc) {
            user {
              id
            }
            totalBuyAmount
            rewardAmount
            profit
          }
        }
      `,
      { first, skip }
    )
    user = response.rounds
  } catch (error) {
    // console.error(error)
    user = blankUser
  }
  return user
}
