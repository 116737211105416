import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import CertikIcon from "../../images/certik-logo.png"
import CertikScore from "../../images/certik-score.svg"
import CertikPolygonLogo from "../../images/certik-polygon-logo.svg"
import CertikSkynet from "../../images/certik-skynet.svg"
import CertikKyc from "../../images/certik-kyc-logo.png"
// import CircleScore from "../../images/circle-score.png"

const Security = () => {
  const { t } = useTranslation()

  return (
    <section className="relative z-10 py-10 lg:pt-11 my-8" id="security">
      <div className="relative container mx-auto">
        <div className="relative lg:px-0 inline-block text-2xl font-medium mb-8 lg:mb-16">
          <span className="relative">{t("Protocol Security")}</span>
        </div>
        <div className="relative items-center flex flex-col lg:flex-row justify-center mx-auto lg:gap-x-8 gap-y-5">
          <div className="px-0 lg:w-2/6 w-72">
            {/* <div className="text-base items-center font-medium lg:flex lg:text-3xl">
              <div className="lg:text-2xl pr-4 lg:border-r-4 border-image-green-gradient">
                Security
              </div>
            </div> */}
            <div className="grid grid-cols-1 gap-2 mt-2 lg:gap-5">
              <Link
                to="https://www.certik.com/projects/megamoon"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <div className="rounded-lg bg-dark-500 border-gray-100 border px-0 col-span-1 lg:order-1">
                  <div className="h-44 flex justify-center items-center px-3 lg:h-36">
                    <img className="lg:w-2/3" src={CertikIcon} alt="Certik" />
                  </div>
                </div>
              </Link>
              <div className="rounded-lg bg-dark-500 border-gray-100 border px-0 col-span-1 lg:order-1">
                <div className="h-44 flex justify-center items-center px-3 lg:h-36">
                  <img
                    className="w-4/5"
                    src={CertikSkynet}
                    alt="Certik Skynet"
                  ></img>
                </div>
              </div>
              <div className="rounded-lg bg-dark-500 border-gray-100 border px-0 col-span-1 lg:order-1">
                <div className="h-44 flex justify-center items-center px-3 lg:h-36">
                  <img className="w-4/6" src={CertikKyc} alt="Certik Kyc"></img>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-2/6 w-72 rounded-2xl border border-sky-300	">
            <div className="container mx-auto lg:px-0 text-base font-medium px-0 py-2 lg:py-4 lg:text-2xl text-center border-b border-sky-300">
              {t("Security Highlights")}
            </div>
            <div className="p-5 text-center mx-4 lg:mx-0 lg:w-full lg:h-full">
              <div className="relative">
                {/* <div className="flex justify-center">
                  <img src={CircleScore} alt="Megamoon Circle Score" />
                </div> */}
                <div className="h-48 flex justify-center items-center lg:h-56">
                  <div className="relative">
                    <img
                      className="w-40 lg:w-60"
                      alt="Certik Score"
                      src={CertikScore}
                    />
                    <img
                      className="absolute left-1/2 transform -translate-x-1/2 top-full -translate-y-8 lg:-translate-y-12 w-20 lg:w-28 h-auto"
                      src={CertikPolygonLogo}
                      alt="Certik Polygon Logo"
                    />
                  </div>
                </div>
                <p className="h-16 text-l lg:h-14 lg:mt-8 mt-3">
                  {t(
                    "MegaMoon scored for security on Polygon Chain as assessed by Certik (October, 2022)"
                  )}
                </p>
                <Link
                  className="block mt-10 text-xs font-bold lg:mt-4"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  to="https://www.certik.com/projects/megamoon"
                >
                  {t("Learn more")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Security
