import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
// import CertikLogo from "../../images/certik.png"
// import PolygonLogo from "../../images/polygon.png"
// import ChainlinkLogo from "../../images/Chainlink-Logo-White.svg"
// import CoinmarketcapLogo from "../../images/coinmarketcap-logo.png"
// import DappRadarLogo from "../../images/dappRadar.png"

const Partner = () => {
  const { t } = useTranslation()

  return (
    <section className="lg:relative">
      <div className="relative container mx-auto mt-8 lg:mt-16">
        <div className="relative lg:px-0 inline-block text-2xl font-medium mb-8 lg:mb-16">
          <span className="relative">{t("Our Partners")}</span>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-2 container mx-auto gap-4 lg:gap-8">
          <Link
            className="col-span-1 p-2 border border-gray-100 rounded-xl flex items-center lg:py-3 lg:px-4 justify-center"
            to="https://www.certik.com/projects/megamoon"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <StaticImage
              className="w-4/5 sm:w-2/5"
              src="../../images/certik-logo.png"
              alt="Certik"
              placeholder="blurred"
            ></StaticImage>
          </Link>
          <Link
            className="col-span-1 p-2 border border-gray-100 rounded-xl flex items-center lg:py-3 lg:px-4 justify-center"
            to="https://polygon.technology/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <StaticImage
              className="w-4/5 sm:w-2/5"
              src="../../images/polygon.png"
              alt="Polygon"
              placeholder="blurred"
            ></StaticImage>
          </Link>
          <Link
            className="col-span-1 p-2 border border-gray-100 rounded-xl flex items-center lg:py-3 lg:px-4 justify-center"
            to="https://chain.link/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <StaticImage
              className="w-4/5 sm:w-2/5"
              src="../../images/chainlink-logo-white.svg"
              alt="Chainlink"
              placeholder="blurred"
            ></StaticImage>
          </Link>
          <Link
            className="col-span-1 p-2 border border-gray-100 rounded-xl flex items-center lg:py-3 lg:px-4 justify-center"
            to="https://coinmarketcap.com/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <StaticImage
              className="w-4/5 sm:w-2/5"
              src="../../images/coinmarketcap-logo.png"
              alt="Coinmarketcap"
              placeholder="blurred"
            ></StaticImage>
          </Link>
          <Link
            className="col-span-1 p-2 border border-gray-100 rounded-xl flex items-center lg:py-3 lg:px-4 justify-center"
            to="https://dappradar.com/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <StaticImage
              className="w-4/5 sm:w-2/5"
              src="../../images/dappRadar-logo.png"
              alt="DappRadar"
              placeholder="blurred"
            ></StaticImage>
          </Link>
          <Link
            className="col-span-1 p-2 border border-gray-100 rounded-xl flex items-center lg:py-3 lg:px-4 justify-center"
            to="https://playtoearn.net/blockchaingame/megamoon"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <StaticImage
              className="w-4/5 sm:w-2/5"
              src="../../images/playtoearn-logo.webp"
              alt="Playtoearn"
              placeholder="blurred"
            ></StaticImage>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Partner
