import { ethers } from "ethers"
import { simpleRpcProvider } from "../utils/providers"
import lotteryV3Abi from "../config/abi/lotteryV3.json"

export const getContract = (abi, address) => {
  //   const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, simpleRpcProvider)
}

export const getLotteryV3Contract = () => {
  return getContract(lotteryV3Abi, "0xA73dA7954834260d7909c697Eb6022e46A5924DE")
}
