import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
// import Core1 from "../../images/core1.webp"
// import Core2 from "../../images/core2.webp"
// import Core3 from "../../images/core3.webp"

const Core = () => {
  const { t } = useTranslation()

  return (
    <section className="lg:relative py-10 lg:py-11">
      <div className="grid grid-cols-1 container mx-auto gap-0 md:grid-cols-3 md:gap-0">
        <div className="col-span-1 items-center p-4 flex md:flex-col md:items-center md:py-6 md:px-8">
          <StaticImage
            className="w-1/2 md:w-full h-auto md:mx-auto"
            src="../../images/core1-image.webp"
            alt=""
            placeholder="blurred"
            quality={100}
          ></StaticImage>
          <div className="w-1/2 md:mt-9 text-center text-lg lg:mt-0 lg:text-2xl">
            {t("Community Owned")}
          </div>
        </div>
        <div className="col-span-1 items-center p-4 flex md:flex-col md:items-center md:py-6 md:px-8 flex-row-reverse border-white lg:border-l lg:border-r gap-y-[13px]">
          <StaticImage
            className="w-1/2 md:w-full h-auto md:mx-auto"
            src="../../images/core2-image.webp"
            alt=""
            placeholder="blurred"
            quality={100}
          ></StaticImage>
          <div className="w-1/2 md:mt-9 text-center text-lg lg:mt-0 lg:text-2xl">
            {t("Big Prizes")}
          </div>
        </div>
        <div className="col-span-1 items-center p-4 flex md:flex-col md:items-center md:py-6 md:px-8">
          <StaticImage
            className="w-1/2 md:w-full h-auto md:mx-auto"
            src="../../images/core3-image.webp"
            alt=""
            placeholder="blurred"
            quality={100}
          ></StaticImage>
          <div className="w-1/2 md:mt-9 text-center text-lg lg:mt-0 lg:text-2xl">
            {t("Fair launch")}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Core
